@font-face {
	font-family: 'Poppins';
	src: local('Poppins'), url(fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'Trebuchet MS';
	font-style: normal;
	font-weight: normal;
	src: local('Trebuchet MS'), url('fonts/Trebuchet/trebuc.woff') format('woff');
}

body {
	margin: 0;
	font-family: 'Trebuchet MS', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition: background-color 600000s 0s, color 600000s 0s;
}
